import React, {
  useContext,
  // useRef,
  // useState,
} from "react";
import { Container, Col, Row } from "react-bootstrap";
import classes from "./Portfolio.module.css";
import Two from "../../Assets/Projects/2.png";
import One from "../../Assets/Projects/1.png";
import Three from "../../Assets/Projects/3.png";
import Four from "../../Assets/Projects/4.png";
import Five from "../../Assets/Projects/5.png";
// import Six from "../../Assets/Projects/6.png";
// import Seven from "../../Assets/Projects/7.png";
import PortfolioCard from "../../components/PortfolioCard/PortfolioCard";
import { MyContext } from "../../Context/MyContext";
// import DownloadButton from "../../components/DownloadButton/DownloadButton.";

export default function Portfolio() {
  // const [showAll, setShowAll] = useState(false);
  // const lastRef = useRef();
  const { appointmentRef, portfolioRef } = useContext(MyContext);

  // function handleShowAll() {
  //   if (!showAll) {
  //     setShowAll(true);
  //     setTimeout(() => {
  //       if (lastRef.current) {
  //         lastRef.current.scrollIntoView({
  //           behavior: "smooth",
  //           block: "center",
  //         });
  //       }
  //     }, 0);
  //   } else {
  //     setShowAll(false);
  //   }
  // }

  return (
    <section ref={portfolioRef} style={{ position: "relative" }}>
      <Container fluid className="home-portfolio" id="home">
        <Container className="home-content" style={{ minHeight: "47rem" }}>
          <div className={classes.container}>
            <Row style={{ flex: 1, display: "flex", gap: "10px" }}>
              <Col
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  padding: "0px",
                  minWidth: "300px",
                }}
              >
                <div>
                  <div className={classes.titleColumnTitle}>
                    A BRIEF OVERVIEW OF OUR RECENT WORK
                  </div>
                  <button
                    onClick={() => {
                      window.open("https://calendly.com/digitalination1/30min", "_blank")
                      window.location.hash = "#book-appointment";
                      // appointmentRef.current.scrollIntoView({
                      //   behavior: "smooth",
                      //   block: "start",
                      // });
                    }}
                    className="buttonHome"
                  >
                    WANT US TO WORK FOR YOU?
                  </button>
                </div>
              </Col>
              <Col
                style={{
                  flex: 1,
                  padding: "0px",
                  maxHeight: "300px",
                  minWidth: "300px",
                }}
                className={classes.serviceColumn}
              >
                <PortfolioCard
                  image={One}
                  title="Match4You"
                  description={`
                        A visually stunning matchmaking website enables real-time interactions among users, consultants, the operations department, and administrators. It features a sleek, intuitive interface for seamless communication and efficient matchmaking. Integrated with the Zoom API, the platform allows users to book meetings directly, ensuring smooth scheduling and consultations for all parties involved.
                        `}
                />
              </Col>
            </Row>
            <Row
              style={{
                flex: 1,
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Col
                style={{
                  flex: 1,
                  padding: "0px",
                  maxHeight: "300px",
                  minWidth: "300px",
                }}
                className={classes.serviceColumn}
              >
                <PortfolioCard
                  title="IBI"
                  image={Two}
                  description={`
                        A visually stunning landing page for IBI, a leading tutoring website for IB students with experienced tutors.
                        `}
                />
              </Col>
              <Col
                style={{
                  flex: 1,
                  padding: "0px",
                  maxHeight: "300px",
                  minWidth: "300px",
                }}
                className={classes.serviceColumn}
              >
                <PortfolioCard
                  title="IBI Portal"
                  image={Three}
                  description={`
                        A portal to connect students with tutors. Admin oversees all activities and manages connection between students and tutors.
                        `}
                />
              </Col>
              <Col
                style={{
                  flex: 1,
                  padding: "0px",
                  maxHeight: "300px",
                  minWidth: "300px",
                }}
                className={classes.serviceColumn}
              >
                <PortfolioCard
                  title="TMS"
                  image={Four}
                  description={`
                        A stunning landing page for Travel Marketing Services. Admin can upload new packages and users can contact them via Contact Us form.
                        `}
                />
              </Col>
              <Col
                style={{
                  flex: 1,
                  padding: "0px",
                  maxHeight: "300px",
                  minWidth: "300px",
                }}
                className={classes.serviceColumn}
              >
                <PortfolioCard
                  title="Scorify (Android)"
                  image={Five}
                  description={`
                        An appealing cricket scoring mobile application. Can be used to calculate scoring for one match or full tournament. Scoring of current matches can be continued via match code.
                        `}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    </section>
  );
}
