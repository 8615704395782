import React, { useContext } from "react";

import { Container, Row, Col } from "react-bootstrap";

import classes from "./Footer.module.css";

import logo from "../../Assets/logo/1 b - Copy.png";
import { MyContext } from "../../Context/MyContext";
import "../SocialIcons/Icons.css";

function Footer() {
  // };
  const { appointmentRef } = useContext(MyContext);
  let date = new Date();
  let year = date.getFullYear();

  return (
    <section style={{ position: "relative", zIndex: 20 }}>
      <Container fluid className="footer-section" id="home">
        <Container className="footer-content">
          <div className={classes.Footer}>
            <div className="footerImageText">LET'S TALK ABOUT PROJECT</div>
            <div className={classes.para}>
              At DigitaliNation we specialize in designing, marketing, web
              development, mobile application development, email marketing,
              graphic designing and virtual assistance.
            </div>
            <div style={{ margin: "20px 10px" }}>
              <button
                onClick={() => {
                  window.location.hash = "#book-appointment";
                  window.open("https://calendly.com/digitalination1/30min", "_blank")
                  // appointmentRef.current.scrollIntoView({
                  //   behavior: "smooth",
                  //   block: "start",
                  // });
                }}
                className="buttonHome"
              >
                LET'S TALK
              </button>
            </div>
            <div style={{ marginTop: "40px" }} className={classes.para}>
              @2024 DigitaliNation. All Rights Reserved
            </div>

            <div
              style={{
                flex: 1,
                maxWidth: "90%",
                margin: "30px auto",
                borderBottom: "1px solid #ccc",
                opacity: "0.5",
              }}
            ></div>

            <Row
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <Col className="footer-copywright">
                <div>
                  <img src={logo} height={"20px"} width="auto" alt="" />{" "}
                  DigitaliNation
                </div>
              </Col>
              <Col className="footer-copywright">
                <div>Copyright © {year}</div>
              </Col>
              {/* <Col md="4" className="footer-body" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
             
              <Icons />
        </Col> */}
            </Row>
          </div>
        </Container>
      </Container>
    </section>
  );
}

export default Footer;
