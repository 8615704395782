import React, { useContext, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import classes from "./Banner.module.css";
import homeImageMain from "../../Assets/homeImageMain.png";
import { MyContext } from "../../Context/MyContext";
import Tilt from "react-parallax-tilt";
import { gsap } from "gsap";
import { MouseParallax } from "react-just-parallax";

function Banner({ loading }) {
  const { aboutUsRef } = useContext(MyContext);
  // const headingRef = useRef();
  const imageRef = useRef();
  const slogan = "Elevate your digital presence";

  // useEffect(() => {
  //   let textAnimation = gsap.timeline();

  //   if (!loading) {
  //     textAnimation.fromTo(
  //       headingRef.current,
  //       {
  //         x: -600,
  //         scale: 0.5,
  //       },
  //       {
  //         x: 0,
  //         scale: 0.5,
  //         delay: 1,
  //       }
  //     );

  //     textAnimation.to(headingRef.current, {
  //       scale: 1,
  //       delay: 0.5,
  //     });
  //   }
  // }, [loading]);

  useEffect(() => {
    if (!loading) {
      const tl = gsap.timeline();

      tl.fromTo(
        imageRef.current,
        {
          scale: 1.5,
          opacity: 0,
          x: 200,
        },
        {
          scale: 1,
          opacity: 1,
          x: 0,
          duration: 1,
          ease: "power3.out",
          delay: 1,
        }
      ).fromTo(
        imageRef.current,
        {
          clipPath: "inset(0% 100% 0% 0%)",
        },
        {
          clipPath: "inset(0% 0% 0% 0%)",
          duration: 1,
          ease: "power2.inOut",
        },
        "-=0.7"
      );
    }
  }, [loading]);

  return (
    <section style={{ position: "relative" }}>
      <div fluid className="home-section" id="home">
        <Container
          ref={imageRef}
          className="home-content"
          style={{ paddingTop: "6rem" }}
        >
          <div className={classes.textContainer}>
            <div>
              <div className={classes.upperText}>
                ONE STOP DIGITAL SOLUTIONS
              </div>
              <div className={classes.middleText}>{slogan?.toUpperCase()}</div>
              <button
                onClick={() => {
                  aboutUsRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
                className="buttonHome"
              >
                ABOUT US
              </button>
            </div>
          </div>
          <div className={classes.imageContainer}>
            <Tilt tiltReverse={true} tiltMaxAngleX={10} tiltMaxAngleY={10}>
              <MouseParallax strength={0.03}>
                <img
                  src={homeImageMain}
                  alt="Digitalination Hero"
                  style={{ width: "100%", height: "auto", pointerEvents: 'none' }}
                />
              </MouseParallax>
            </Tilt>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Banner;
