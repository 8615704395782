import React, { useEffect, useRef } from "react";
import Footer from "../../components/Footer/Footer";
// import Appointment from "../BookAppointment/Appointment";
import AboutUs from "./AboutUs";
import Banner from "./Banner";
import CompaniesWorkingWith from "./CompaniesWorkingWith";
import ContactUs from "./ContactUs";
import OurServices from "./OurServices";
import OurTeam from "./OurTeam";
import Portfolio from "./Portfolio";
import SubscribeEmail from "./SubscribeEmail";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Home({ loading }) {

  const isMobile = window.innerWidth < 767;
  const whatsappRef = useRef();
  const sectionsRef = useRef([]);

  useEffect(() => {
    if (!loading) {
      gsap.fromTo(
        whatsappRef.current,
        {
          x: 100,
          opacity: 0.7,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.5,
          delay: 2.7,
        }
      );

      if (!isMobile) {
      sectionsRef.current.forEach((section, index) => {
        gsap.fromTo(
          section,
          {
            opacity: 0,
            scale: 0.5,
            clipPath: "circle(0% at 50% 50%)",
          },
          {
            opacity: 1,
            scale: 1,
            clipPath: "circle(100% at 50% 50%)",
            scrollTrigger: {
              trigger: section,
              start: "top 100%",
              end: "bottom 20%",
              scrub: true,
            },
            duration: 1.5,
            ease: "power2.out",
          }
        );
      });
    }
    }
  }, [loading, isMobile]);



  return (
    <>
    <section
      style={{
        display: loading ? "none" : "block",
        position: "relative",
        zIndex: 20,
        background: '#0d0d13'
      }}
    >

      <>
        <Banner loading={loading} />
        <div className="section" ref={(el) => (sectionsRef.current[0] = el)}>
          <CompaniesWorkingWith />
        </div>
        <div className="section" ref={(el) => (sectionsRef.current[1] = el)}>
          <AboutUs />
        </div>
        <div className="section" ref={(el) => (sectionsRef.current[2] = el)}>
          <OurServices />
        </div>
        <div className="section" ref={(el) => (sectionsRef.current[3] = el)}>
          <Portfolio />
        </div>
        <div className="section" ref={(el) => (sectionsRef.current[4] = el)}>
          <OurTeam />
        </div>
        <div className="section" ref={(el) => (sectionsRef.current[5] = el)}>
          <ContactUs />
        </div>
        {/* <div className="section" ref={(el) => (sectionsRef.current[6] = el)}>
          <Appointment />
        </div> */}
        <div className="section" ref={(el) => (sectionsRef.current[6] = el)}>
          <SubscribeEmail />
        </div>
        <div className="section" ref={(el) => (sectionsRef.current[7] = el)}>
          <Footer />
        </div>
        <div ref={whatsappRef} className="socialArea">
          <button
            className="whatsappButtonMain"
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send/?phone=%2B923335469811&text&type=phone_number&app_absent=0",
                "_blank"
              );
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              class="w-6 h-6 text-gray-800 dark:text-white"
            >
              <path
                clip-rule="evenodd"
                d="M12 4a8 8 0 0 0-6.895 12.06l.569.718-.697 2.359 2.32-.648.379.243A8 8 0 1 0 12 4ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382 1.426-4.829-.006-.007-.033-.055A9.958 9.958 0 0 1 2 12Z"
                fill-rule="evenodd"
                fill="currentColor"
              ></path>
              <path
                d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1.008 1.008 0 0 0-.34-.075c-.196 0-.362.098-.49.291-.146.217-.587.732-.723.886-.018.02-.042.045-.057.045-.013 0-.239-.093-.307-.123-1.564-.68-2.751-2.313-2.914-2.589-.023-.04-.024-.057-.024-.057.005-.021.058-.074.085-.101.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711-.158-.377-.366-.552-.655-.552-.027 0 0 0-.112.005-.137.005-.883.104-1.213.311-.35.22-.94.924-.94 2.16 0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537 1.412.564 2.081.63 2.461.63.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276.192-.534.243-1.117.115-1.329-.088-.144-.239-.216-.43-.308Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </>
    </section>
    </>
  );
}

export default Home;
