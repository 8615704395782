import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import classes from "./OurTeam.module.css";

import ourTeam from "../../Assets/Digitali Nation presentation.pdf-image-014.jpg";
import Testimonial from "../../components/Testimonial/Testimonial";
import { MyContext } from "../../Context/MyContext";

import { Carousel } from "react-bootstrap";

export default function OurTeam() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { appointmentRef } = useContext(MyContext);

  const containerRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && cardRef.current) {
      containerRef.current.style.minHeight = `${cardRef.current.offsetHeight}px`;
    }
  }, []);

  const testimonials = [
    {
      value: 4.5,
      title: "Matthew Harris",
      review:
        "The software development services provided were exceptional. The team delivered a robust and scalable solution for our business needs.",
    },
    {
      value: 5,
      title: "Ashley White",
      review:
        "Outstanding mobile application development! The app is intuitive, fast, and has received fantastic feedback from our users.",
    },
    {
      value: 5,
      title: "Tayyab Nasir",
      review:
        "Their social media marketing strategies have significantly boosted our online presence. Our engagement rates have skyrocketed!",
    },
    {
      value: 5,
      title: "Ali Siddiqui",
      review:
        "The team provided excellent virtual assistant services. They are reliable, efficient, and have become an integral part of our operations.",
    },
    {
      value: 5,
      title: "Armagham Zia",
      review:
        "Their web development services are outstanding. The website they created for us is fast, secure, and visually appealing.",
    },
    {
      value: 4.5,
      title: "Umair Sabir",
      review:
        "The new website design has enhanced our online presence. Their web development skills are top-notch.",
    },
    {
      value: 5,
      title: "Saboor Hassan",
      review:
        "The mobile application they developed for us is fantastic. It's user-friendly and has great functionality.",
    },
    {
      value: 4.5,
      title: "John Smith",
      review:
        "Their virtual assistant services are a game-changer. Our administrative tasks are handled efficiently, freeing up our time to focus on core activities.",
    },
    {
      value: 4.5,
      title: "Jessica Brown",
      review:
        "We are thrilled with the e-commerce website they developed for us. It’s user-friendly and has helped boost our sales significantly.",
    },
    {
      value: 4.5,
      title: "Michael Johnson",
      review:
        "The team's expertise in SEO has improved our search engine rankings and increased organic traffic to our website.",
    },
    {
      value: 4.5,
      title: "Emily Davis",
      review:
        "Their content marketing strategies have been very effective. Our audience engagement has improved, and we are seeing great results.",
    },
    {
      value: 4.5,
      title: "James Wilson",
      review:
        "The pay-per-click advertising campaigns they managed have provided a great return on investment. Highly recommend their services!",
    },
    {
      value: 4.5,
      title: "Sarah Moore",
      review:
        "The team is very professional and responsive. They delivered an excellent CRM solution tailored to our needs.",
    },
    {
      value: 4.5,
      title: "Robert Taylor",
      review:
        "The new website design has enhanced our online presence. Their web development skills are top-notch.",
    },
    {
      value: 4.5,
      title: "Amanda Anderson",
      review:
        "Their graphic design services exceeded our expectations. The marketing materials they designed are beautiful and effective.",
    },
    {
      value: 4.5,
      title: "David Thomas",
      review:
        "The software solutions they provided have streamlined our business processes. We are very satisfied with their work.",
    },
    {
      value: 5,
      title: "Christopher Martin",
      review:
        "The mobile app development service was superb. The app they created is feature-rich and user-friendly.",
    },
    {
      value: 4.5,
      title: "Patricia Lee",
      review:
        "Their email marketing services have helped us maintain regular communication with our customers. We've seen a significant improvement in customer retention.",
    },
    {
      value: 4.5,
      title: "Linda Clark",
      review:
        "The SEO strategies they implemented have dramatically improved our search engine visibility. We are getting more inquiries and leads than ever before.",
    },
    {
      value: 4.5,
      title: "Mark Lewis",
      review:
        "Their content creation services have been invaluable. The quality and relevance of the content have helped us engage our audience effectively.",
    },
  ];

  return (
    <section style={{ position: "relative" }}>
      <Container fluid className="home-portfolio" id="home">
        <Container className="home-content" style={{ minHeight: "47rem" }}>
          <div className={classes.container}>
            <Row style={{ flex: 1, display: "flex", gap: "10px" }}>
              <Col
                className={classes.firstCol}
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  padding: "0px",
                  minWidth: "300px",
                }}
              >
                <div>
                  <div className={classes.titleColumnTitle}>
                    PEOPLE LOVED OUR TEAM
                  </div>
                  <button
                    onClick={() => {
                      window.location.hash = "#book-appointment";
                      window.open(
                        "https://calendly.com/digitalination1/30min",
                        "_blank"
                      );
                      // appointmentRef.current.scrollIntoView({
                      //   behavior: "smooth",
                      //   block: "start",
                      // });
                    }}
                    className="buttonHome"
                  >
                    LET'S MEET
                  </button>
                </div>
              </Col>
              <Col className={classes.ourTeamCol}>
                <div className="containerMain noselect" ref={containerRef}>
                  <div
                    id="card"
                    ref={cardRef}
                    style={{
                      borderRadius: "1rem",
                      height: "300px",
                      width: "100%",
                      overflow: "hidden",
                    }}
                    className={classes.serviceColumn}
                  >
                    <img
                      src={ourTeam}
                      alt="Our Team"
                      width={"100%"}
                      height={"100%"}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        pointerEvents: 'none'
                      }}
                    />
                  </div>
                </div>

                {/* <img src={Haroon} alt="" className={classes.ourTeamImage} /> */}
              </Col>
            </Row>
            <Row style={{ flex: 1, marginTop: "10px" }}>
              <Carousel
                interval={2000}
                style={{ overflow: "hidden", padding: "0px" }}
                touch={true}
                slide={true}
                indicators={false}
                controls={false}
              >
                {isMobile
                  ? testimonials?.map((item, index) => (
                      <Carousel.Item key={index}>
                        <div style={{ display: "flex", width: "100%" }}>
                          <Testimonial
                            title={item?.title}
                            review={item.review}
                            value={item.value}
                          />
                        </div>
                      </Carousel.Item>
                    ))
                  : testimonials
                      ?.reduce((acc, item, index) => {
                        const groupIndex = Math.floor(index / 4);
                        if (!acc[groupIndex]) acc[groupIndex] = [];
                        acc[groupIndex].push(
                          <div key={index} style={{ flex: 1, width: "25%" }}>
                            <Testimonial
                              title={item?.title}
                              review={item.review}
                              value={item.value}
                            />
                          </div>
                        );
                        return acc;
                      }, [])
                      .map((group, index) => (
                        <Carousel.Item key={index}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              width: "100%",
                              flexWrap: "wrap",
                            }}
                          >
                            {group}
                          </div>
                        </Carousel.Item>
                      ))}
              </Carousel>
            </Row>
          </div>
        </Container>
      </Container>
    </section>
  );
}
