import React, { useState, useEffect, useRef } from "react";
import classes from "./Loading.module.css";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { easeQuadInOut } from "d3-ease";
import { gsap } from "gsap";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#fff',
  },
}));

export default function Loading({loading, setLoading}) {
  const [loadingValue, setLoadingValue] = useState(0);
  const logoContainerRef = useRef(null);
  const loadingRef = useRef(null);

  useEffect(() => {
    if (!loading) {
      const tl = gsap.timeline();
      tl.to(logoContainerRef.current, {
        y: -300,
        duration: 1,
        ease: "power2.out"
      });
  
      tl.to(`.${classes.loadingContainer}`, {
        opacity: 0,
        duration: 1,
        ease: "power2.out"
      }, "-=1");
  
      tl.to(loadingRef.current, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
          loadingRef.current.style.display = "none";
        },
      });
    }
  }, [loading]);

  // INITIAL ANIMATION
  // useEffect(() => {
  //   const tl = gsap.timeline();
  
  //   tl.fromTo(
  //     logoContainerRef.current,
  //     {
  //       y: -300,
  //     },
  //     {
  //       y: 0,
  //       duration: 1,
  //       ease: "power2.out",
  //       delay: 0.5
  //     }
  //   );
  
  //   tl.fromTo(
  //     `.${classes.loadingContainer}`,
  //     {
  //       y: 300,
  //       opacity: 0,
  //     },
  //     {
  //       y: 0,
  //       opacity: 1,
  //       duration: 1,
  //       ease: "power2.out",
  //       delay: 0.5
  //     },
  //     "-=1.5"
  //   );
  // }, []);

  useEffect(() => {
    let start = null;
    const duration = 3000; // 3 seconds

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / duration, 1); // Ensure it caps at 1
      const easedProgress = easeQuadInOut(progress);
      setLoadingValue(easedProgress * 100);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    if (logoContainerRef.current) {
      logoContainerRef.current.style.setProperty('--width-percentage', `${loadingValue}%`);
    }
    if(loadingValue === 100){
        setLoading(false)
    }
  }, [loadingValue, setLoading]);

  return (
    <div ref={loadingRef} className={classes.container}>
      
      <div className={classes.contentContainer}></div>

    <div style={{overflow: 'hidden'}}>
      <div
        // data-text="SYSVIT"
        className={classes.logoContainer}
        ref={logoContainerRef}
      >
        {/* SYSVIT */}
      </div>
      </div>
<div style={{overflow: 'hidden'}}>
      <div className={classes.loadingContainer}>
        <div className={classes.loading}>
          Loading {loadingValue?.toFixed(0)}%
          <BorderLinearProgress
            variant="determinate"
            value={loadingValue}
            className={classes.loadingBar}
          />
        </div>
      </div>
      </div>
    </div>
  );
}
