import React, { useContext,  useEffect,  useRef,  useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo/1 b.png";
import { Link,  useNavigate } from "react-router-dom";
import { MyContext } from "../Context/MyContext";
import { useMediaQuery } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function NavBar({loading}) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const headerRef = useRef();
  let lastScrollTop = 0;
  const isMobileQuery = useMediaQuery('(max-width:767px)');
  const [isMobile, setIsMobile] = useState(false);

    
  useEffect(() => {
    if(!loading){
    gsap.fromTo(headerRef.current, {
      y: -200,
      opacity: 0.7
    },
    {
      y: 0,
      opacity: 1,
      duration: 0.5,
      delay: 2.3,
    }
  )
}
  }, [loading])

    // SCROLL HANDLER
  // SCROLL HANDLER
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollDifference = Math.abs(currentScrollTop - lastScrollTop);

      // Change header background color when scroll exceeds 100vh
      if (currentScrollTop > 150) {
        gsap.to(headerRef.current, {
          backgroundColor: "#0d0d13",
          borderBottom: "1px solid #8e8e8e",
          duration: 0.5,
          ease: "power2.out",
        });
      } else {
        gsap.to(headerRef.current, {
          backgroundColor: "transparent",
          borderBottom: 'none',
          duration: 0.5,
          ease: "power2.out",
        });
      }

      // Always show header if scroll is at the top
      if (currentScrollTop === 0) {
        gsap.to(headerRef.current, {
          y: "0%",
          duration: 0.5,
          ease: "power2.out",
        });
      } else if (scrollDifference > 0) {
        if (currentScrollTop > lastScrollTop) {
          // Scrolling down
          gsap.to(headerRef.current, {
            y: "-100%",
            duration: 0.5,
            ease: "power2.out",
          });
        } else {
          // Scrolling up
          gsap.to(headerRef.current, {
            y: "0%",
            duration: 0.5,
            ease: "power2.out",
          });
        }

        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsMobile(isMobileQuery);
  }, [isMobileQuery]);


  // const location = useLocation();
  const navigate = useNavigate();
  const { serviceRef, contactUsRef, appointmentRef, portfolioRef } =
    useContext(MyContext);




  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);


  return (
    <Navbar
    ref={headerRef}
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
      style={{transform: 'translateY(-200px)'}}
    >
      <Container>
        <Navbar.Brand
          onClick={() => {
            navigate("/");
            window.location.hash = "#."
            window.scrollTo(0, 0);
          }}
          className="d-flex"
        >
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">

            <Nav.Item>
              <Nav.Link
                as={Link}
                to={"#services"}
                onClick={() => {
                  updateExpanded(false);
                  window.location.hash = "#services"
                  serviceRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              >
                SERVICES
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="#our-work"
                onClick={() => {
                  updateExpanded(false);
                  window.location.hash = "#our-work"
                  portfolioRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              >
                OUR WORK
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to={"#contact-us"}
                onClick={() => {
                  updateExpanded(false);
                  window.location.hash = "#contact-us"
                  contactUsRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
              >
                CONTACT US
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="fork-btn">
              <button
                as={Link}
                className="buttonHome"
                style={{
                  marginTop: '0px',
                  padding: "0px 10px",
                  maxHeight: "30px",
                  height: "max-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  flex: 1,
                  alignSelf: 'center',
                  width: '100%',
                  color: "#fff",
                  marginLeft: isMobile ? "0px" : '10px'
                }}
                onClick={() => {
                  updateExpanded(false);
                  // window.location.hash = "#book-appointment";
                  // appointmentRef.current.scrollIntoView({
                  //   behavior: "smooth",
                  //   block: "start",
                  // });
                  window.open("https://calendly.com/digitalination1/30min", "_blank")
                }}
              >
                BOOK A CALL
              </button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
