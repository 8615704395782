import React from 'react';
import "./PortfolioCard.css"

export default function PortfolioportfolioCard({image, title, description}){


    return(
      <div class="portfolioCard">
        <img src={image} alt={""} />
      <div class="portfolioCard__content">
        <p class="portfolioCard__title">{title ? title : "Title"}</p>
        <p class="portfolioCard__description">{description ? description : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.`}</p>
      </div>
    </div>
    )
}