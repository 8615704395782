import React, { useState, useEffect, useContext } from "react";
import classes from "./ContactUs.module.css";
import { db } from "../../firebase";
import { collection, addDoc,  updateDoc } from "firebase/firestore";
import { TextField, Button } from "@mui/material";
import { toast } from "react-hot-toast";
import emailjs from "emailjs-com";
import { MyContext } from "../../Context/MyContext";
import { Container } from "react-bootstrap";

export default function ContactUs() {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [howCanWeSupport, setHowCanWeSupport] = useState("");
  const [submitting, setSubmitting] = useState(false)

  const {contactUsRef} = useContext(MyContext)


  const [flexSet, setFlexSet] = useState(false);

  const checkIsMobile = () => {
    setFlexSet(window.innerWidth <= 650); // You can adjust the width threshold as needed
  };

  useEffect(() => {
    checkIsMobile(); // Initial check
    window.addEventListener("resize", checkIsMobile); // Add event listener

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

const handleSubmit = async (e) => {
  e.preventDefault()
  const formData={
    name: firstName,
    email: email,
    message: howCanWeSupport,
    submittedOn: new Date()
  }

  
  try {
    setSubmitting(true);
  const collectionRef = collection(db, "contactUs");
  const docRef = await addDoc(collectionRef, formData);

  await updateDoc(docRef, { id: docRef.id });

  
  const emailParams = {
    send_to: "haroonabsar3@gmail.com, annaskhalid2001@gmail.com",
    subject: `${firstName} submitted Contact Us form on the website`,
    message: `
    <div>
    <h3>Appointment Details:</h3>
    <span><b>Name: <b>${firstName}</span><br/>
    <span><b>Email: <b>${email}</span><br/>
    <span><b>Message: <b>${howCanWeSupport}</span><br/>
    </div>
    `
  };

  await emailjs.send(
    "service_dkicr1e",
    "template_j4fknxn",
    emailParams,
    process.env.REACT_APP_EMAILID
  );


  setFirstName("");
  setEmail("");
  setHowCanWeSupport("");
  
  toast.success("Submitted", {
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  });

} catch (e) {
  toast.error("Error occurred, Please try again", {
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
  });
} finally{
  setSubmitting(false);
}


};

  return (
    <section style={{position: 'relative', zIndex: 21}}>
      <Container className={classes.mainImage} ref={contactUsRef}>
        

        <div
          style={{
            marginTop: "0px",
            marginBottom: "2rem",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: 'stretch',
            flexDirection: flexSet ? "column" : "row",
            flex: 1,
            gap: "20px",
              borderRadius: "10px",
              position: 'relative'
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
            <div style={{flex: 1}}>
      <div className={classes.title}>CONTACT US</div>
    <div style={{color: '#ccc'}}>digitalination1@gmail.com</div>
    </div>
    </div>

          <div
            style={{
              marginTop: "0px",
              flex: 1,
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  style={{ flex: 1,
                    backdropFilter: "blur(10px)", 
                    WebkitBackdropFilter: "blur(10px)",
                   }}
                  label="Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  type="text"
                  id="firstName"
                  name="firstName"
                  required
                />

                <TextField
                  style={{ flex: 1,
                    backdropFilter: "blur(10px)", 
                    WebkitBackdropFilter: "blur(10px)",
                   }}
                  label="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  id="email"
                  name="email"
                  required
                />
              </div>

              <div style={{ flex: 1, width: "100%", marginBottom: "20px" }}>
                <TextField
                  style={{ flex: 1,
                    backdropFilter: "blur(10px)", 
                    WebkitBackdropFilter: "blur(10px)",
                    width: "100%",
                   }}
                  label="Message"
                  multiline
                  value={howCanWeSupport}
                  onChange={(e) => {
                    setHowCanWeSupport(e.target.value);
                  }}
                  id="support"
                  name="support"
                  rows="4"
                  required
                />
              </div>
              <Button
                variant="contained"
                style={{
                  width: "100%",
                  background: submitting ? "transparent" : "linear-gradient(32deg, #5bd7f1, #148af8, #148af8, #5bd7f1)",
                  color: "#fff"
                }}
                type="submit"
              >
                {submitting ? "Submitting" : "Submit"}
              </Button>
            </form>
          </div>


        </div>
      </Container>

    </section>
  );
}
