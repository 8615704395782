import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MyContextProvider } from "./Context/MyContext";


ReactDOM.render(
  // <React.StrictMode>
    <MyContextProvider>
    <App />
    </MyContextProvider>
  // </React.StrictMode>
  ,
  document.getElementById("root")
);
