import React, { useState } from "react";
import Navbar from "./pages/Navbar";
import Home from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./pages/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Toaster } from "react-hot-toast";
import Loading from "./components/Loading/Loading";
// import { Helmet } from "react-helmet";

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { CustomEase } from "gsap/CustomEase";
import { RoughEase, ExpoScaleEase, SlowMo } from "gsap/EasePack";

import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { PixiPlugin } from "gsap/PixiPlugin";
import { TextPlugin } from "gsap/TextPlugin";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  gsap.registerPlugin(
    useGSAP,
    Flip,
    ScrollTrigger,
    Observer,
    ScrollToPlugin,
    Draggable,
    MotionPathPlugin,
    EaselPlugin,
    PixiPlugin,
    TextPlugin,
    RoughEase,
    ExpoScaleEase,
    SlowMo,
    CustomEase
  );
  const [load, updateLoad] = useState(true);
  

  return (
    <Router>
      {/* <Particle /> */}
      <Loading loading={load} setLoading={updateLoad} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Navbar loading={load} />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home loading={load} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ThemeProvider>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </Router>
  );
}

export default App;
